import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { getRankingInformations, ipAdress, loginUser } from '../data/getAxiosRequests';
import { useLocation, useNavigate } from 'react-router-dom';
import { csrftoken } from '../constants/helpers';
import IconButton from '../components/UI/IconButton';
import { SVGs } from '../constants/Svgs';

import { RankingLineProps } from '../constants/types';
import RankingTab from '../components/party/RankingTab';
import RankingTabOneChallenge from '../components/party/RankingTabOneChallenge';
import CustomButton from '../components/UI/CustomButton';
import { TailSpin } from 'react-loader-spinner';
import { Colors } from '../constants/Consts';

const SpecialNightScreen = () => {

    useEffect(() => {
        document.title = "Défis Gaston"},[])
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const uid = queryParams.get('uid');
    const navigate = useNavigate()
    
    // States for storing ranking data
    const [rankingInformationsMale, setRankingInformationsMale] = useState<RankingLineProps[]>([]);
    const [rankingInformationsFemale, setRankingInformationsFemale] = useState<RankingLineProps[]>([]);
    const [isAutoRefreshed, setIsAutoRefreshed] = useState(true);  // Control auto-refresh
    const [showData, setShowData] = useState(false); 
    const [countSessions, setCountSessions] = useState(0)
    const timeLimit = new Date(2024, 9, 10, 21, 0, 0, 0);  // Note: month is 0-indexed, so 5 is June
    const [currentTime, setCurrentTime] = useState(new Date())


    function getTimeLeft(currentTime: Date): string {
        const diff = timeLimit.getTime() - currentTime.getTime();
    
        if (diff < 0) {
            return "C'est terminé ! Place aux récompenses !!";
        }
    
        // Convert milliseconds to minutes, hours, and days
        const minutesTotal = Math.floor(diff / 60000);
        const hoursTotal = Math.floor(minutesTotal / 60);
        const days = Math.floor(hoursTotal / 24);
        const hours = hoursTotal % 24;
        const minutes = minutesTotal % 60;
    
        // Format the response based on the duration
        if (days > 0) {
            const daysText = days === 1 ? "jour" : "jours";
            const hoursText = hours === 1 ? "heure" : "heures";
            return `${days} ${daysText}, ${hours} ${hoursText} et ${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
        } else if (hours > 0) {
            const hoursText = hours === 1 ? "heure" : "heures";
            const minutesText = minutes === 1 ? "minute" : "minutes";
            return `${hours} ${hoursText} et ${minutes} ${minutesText}`;
        } else {
            if (minutes === 0) {
                return "1 minute";
            } else {
                const minutesText = minutes === 1 ? "minute" : "minutes";
                return `${minutes} ${minutesText}`;
            }
        }
    }
    

    useEffect(() => {
        // Function to update 'now'
        if(currentTime < timeLimit) {
            const updateNow = () => {
                const latestTime = new Date();
                setCurrentTime(latestTime);
            };

        // Set an interval to update 'now' every second
            const intervalId = setInterval(updateNow, 60000);
        

        // Cleanup function to clear interval
            return () => clearInterval(intervalId);
        }
        else{
            setIsAutoRefreshed(false)
        }
    }, [currentTime])

    // Function to fetch all ranking data
    const fetchRankingData = async () => {
        try {
            const data = await getRankingInformations();
            setRankingInformationsMale(data.male);
            setRankingInformationsFemale(data.female)
            setCountSessions(data.postCount)
        } catch (error) {
            console.error('Failed to fetch ranking data:', error);
        }
    };

    useEffect(() => {
        fetchRankingData().then(()=>setShowData(true));  // Initial fetch
    }, []);

    // useEffect to handle data fetching and auto-refresh every 20 seconds
    useEffect(() => {
        if (isAutoRefreshed) {
            fetchRankingData();  // Initial fetch
            const intervalId = setInterval(fetchRankingData, 60000);  // Set up interval

            return () => clearInterval(intervalId);  // Cleanup interval on component unmount
        }
    }, [isAutoRefreshed]);


    return (
        <div className='party_outercontainer'>
            <div className='party_header'>
                <div className='logo-gaston'>
                    <IconButton 
                        Svg={SVGs.gastonPartySvg}
                    />
                </div>
                <div style={{alignItems:'center'}}>
                    <p className='heading1 extrabold title_party_header'>Le défi Gaston</p>
                    <p className='heading extrabold title_party_header'>Edition n°2 - Cortigrimpe & Contrepointe</p>
                </div>
                <div className='logo-gym'>
                   <img src={"https://gastonclimbing.s3.eu-central-1.amazonaws.com/images/images/cortiRoundedLogo.png"} style={{height:104, width:104}}/>
                   <img src={"https://gastonclimbing.s3.eu-central-1.amazonaws.com/images/images/ContrepointeRoundedLogo.png"} style={{height:104, width:104}}/>
                </div>
            </div>
            <div className='party_subHeader'>
                <div className='party_subHeader_component'>
                    <IconButton 
                        Svg={SVGs.clock}
                    />
                    {timeLimit.getTime() - currentTime.getTime() <= 0  ?
                        <p className='large3 bold title_party_header'>C'est terminé ! Place aux récompenses !!</p>
                        :
                        <p className='large3 bold title_party_header'>Encore {getTimeLeft(currentTime)} pour valider tes blocs !</p>
                    }
                </div>
                <div className='should-be-displayed party_subHeader_component_2 '>
                    <IconButton 
                        Svg={SVGs.checkIn}
                    />
                    {showData ? <p className='large3 bold title_party_header'>{countSessions} séances ajoutées avec Gaston </p> : <TailSpin color={Colors.colorgrey100} />}
                </div>
                <div className='should-be-displayed party_subHeader_component_2 '>
                    <IconButton 
                        Svg={SVGs.gift}
                    />
                    <p className='large3 bold title_party_header'>1 tee-shirt Gaston à gagner et 2 entrées gratuites!</p>
                </div>
            </div>
            <div className='party_ranking_section'>
                <div className='party_ranking_tabs_section_container'>
                    <div className='party_ranking_tabs_section_upper_section'>
                        <CustomButton
                            text=''
                            Svg={SVGs.versatile}
                        />
                        <div className='party_ranking_tabs_text regular large2'>
                            <div className='bold large3'>Assidu(e)s</div>
                            <div className='party_ranking_tabs_subtext'>
                                <div>Fais un maximum de séances : partage tes séances de grimpe au Corti et à Contrepointe sur Gaston. Grimpe et gagne des lots !</div>
                                <div>Une mission pour toi : rentrer tes séances avec tous les blocs que tu as validé et suivre tes progrès sur ce classement !</div>
                            </div>
                        </div>
                    </div>
                    {showData ?
                    <div>
                        <div className='party_ranking_tabs_section'>
                            <RankingTabOneChallenge
                                lines={rankingInformationsMale}
                                
                                shouldPointsBeTransformed={false}
                                category='Cortigrimpe'
                            />
                            <RankingTabOneChallenge
                                lines={rankingInformationsFemale}
                                
                                shouldPointsBeTransformed={false}
                                category='Contrepointe'
                            />
                        </div>
                        
                    </div>
                        :
                  
                            <div >
                                <TailSpin color={Colors.colorprimary400} height={80} width={80} />
                            </div>
                         
                    }
                </div>
            </div>

            
        </div>
    );
}

export default SpecialNightScreen;